import React from "react";
import { Link } from 'gatsby';
import ContentPage from '../components/ContentPage';

import logo from '../images/logofull.png';

const IndexPage = () => {

    return <ContentPage fullscreen={true}>
        <div className='text-center m-t-40'>
        <img src={logo} alt='Coralpeak Systems' className='width-full maxwidth-800' />
        <h1>The Web Application Experts</h1>
        <p>Coralpeak Systems helps businesses to move to the web</p>
        <p>Please take a moment to check out our range of services and find out how we can help you to succeed</p>
        <Link to='/services' className='btn m-t-30'>Find Out More</Link>
        </div>
    </ContentPage>
}

export default IndexPage
